import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import About from "../components/about"
import Seo from "../components/seo"

class AboutPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <About title={siteTitle}>
        <Seo
              title="About"
              keywords={[`blog`, `gatsby`, `javascript`, `react`, `Katrin Valdre`, `nonsie`]}
            />
        <Bio />
      </About>
    )
  }
}

export default AboutPage
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
